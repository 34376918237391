import styled from 'styled-components';

export const ContainerScreens = styled.div`
  width: 100%;
  padding: 5px;

  .capa-tabs {
    button {
      padding: 0 10px;
      height: 24px;
      background: #c6c6c6;
      border: none;

      cursor: pointer;
      & + button {
        margin-left: 2px;
      }
    }
    .active {
      background: ${(props) => props.theme.colors.nav} !important;
      color: #fff;
    }
  }

  .data-comparison {
    /* overflow: hidden; */
    .tab-pane.active {
      animation: slide-left 0.3s ease-out;
    }
    @keyframes slide-left {
      0% {
        opacity: 0;
        transform: translateX(3%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .nav-tabs {
      display: none;
      visibility: hidden;
    }
  }
`;

import api from '~/services/api';

import { FormMarcas, ProdutoProps } from '../types';

interface VerifyReturnProps {
  exists: boolean;
  message: string;
}

export const verifyProducts = async (
  formMarca: FormMarcas,
  formProdutos: ProdutoProps[],
): Promise<VerifyReturnProps> => {
  const res = await api.post('/marcas/check-produtos', {
    cod_marca: formMarca.cod_marca,
    produtos: formProdutos,
  });

  const { exists, message } = res.data;

  return { exists, message };
};

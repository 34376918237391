import api from '~/services/api';
import { toast } from 'react-toastify';
import { NcmProps } from '../../protocols/NcmProps';
import { SelectProps } from '../../protocols/SelectProps';

export async function getNCM(): Promise<SelectProps[]> {
  try {
    const { data } = await api.get(`/manutencao-fiscal/ncm`);
    if (data.data) {
      const ncms = data.data.map((value: NcmProps) => {
        return {
          label: `${value.num_ncm} - ${value.des_ncm}`,
          value: value.cod_ncm,
        };
      });
      return ncms;
    }
    return [];
  } catch (error: any) {
    toast.warning('NCMs não encontrados');
    return [];
  }
}

import api from '~/services/api';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { NfeProps } from '../../protocols/NfeProps';

export async function getNfe(cod_produto: string): Promise<NfeProps[]> {
  try {
    const { data } = await api.get(`/manutencao-fiscal/nfe/${cod_produto}`);
    if (data.data) {
      const nfe = data.data.map((value: NfeProps) => {
        value.id = v4();
        return value;
      });
      return nfe;
    }
    return [];
  } catch (error: any) {
    toast.warning('NFE não encontrada');
    return [];
  }
}

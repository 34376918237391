import styled from 'styled-components';

export const ContainerScreens = styled.div`
  width: 100%;
  padding: 5px;

  .containerFiscal {
    display: flex;
    padding-left: 10px;
    justify-content: flex-end;
  }

  @media screen and (max-width: 767px) {
    .containerFiscal {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .confirm {
        width: 100%;
        margin-bottom: 20px;
      }
      .clear {
        width: 100%;
        margin-left: 0px;
      }
    }
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

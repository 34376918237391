import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: 20px;
  .MuiDataGrid-selectedRowCount {
    color: transparent !important;
  }
  .row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
  }

  .super-app-theme--header {
    background: #8850bf !important;
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;

  &:nth-child(2) {
    margin-left: 200px;
  }

  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
`;

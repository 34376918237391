import api from '~/services/api';
import { toast } from 'react-toastify';
import { EspecieProps } from '../../protocols/EspecieProps';
import { SelectProps } from '../../protocols/SelectProps';

export async function getEspecies(): Promise<SelectProps[]> {
  try {
    const { data } = await api.get(`/manutencao-fiscal/especie`);
    if (data.data) {
      const especie = data.data.map((value: EspecieProps) => {
        return {
          ...value,
          label: `${value.des_especie} - ${value.des_doc_fiscal}`,
          value: value.cod_especie,
        };
      });
      return especie;
    }
    return [];
  } catch (error: any) {
    toast.warning(error.message);
    return [];
  }
}

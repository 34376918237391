import { yupResolver } from '@hookform/resolvers/yup';
import React, { createContext } from 'react';
import { useForm } from 'react-hook-form';

import {
  MarcasContextData,
  MarcasProviderProps,
  UseFormProps,
} from '../types/context';
import { marcaSchema } from '../validation';

export const MarcasContext = createContext({} as MarcasContextData);

export const MarcasProvider: React.FC<MarcasProviderProps> = ({ children }) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(marcaSchema),
  });

  const formTela: UseFormProps = {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  };

  return (
    <MarcasContext.Provider value={{ formTela }}>
      {children}
    </MarcasContext.Provider>
  );
};

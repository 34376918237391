import api from '~/services/api';
import { ItemNFProps, ItemNFiscalProps } from '../../protocols/ItemNFProps';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { formatCurrencyAsText } from '~/utils/functions';

export async function getItensFiscal(
  cod_seq_nf: string | number | undefined,
): Promise<ItemNFProps[]> {
  try {
    const { data } = await api.get(`/manutencao-fiscal/fiscal/${cod_seq_nf}`);
    if (data.data) {
      const itensFiscalNF = data.data.map((item: ItemNFiscalProps) => {
        item.id = v4();
        item.val_bc_icms = formatCurrencyAsText(item.val_bc_icms);
        item.val_bc_st = formatCurrencyAsText(item.val_bc_st);
        item.val_desconto = formatCurrencyAsText(item.val_desconto);
        item.val_despesa_acessoria = formatCurrencyAsText(
          item.val_despesa_acessoria,
        );
        item.val_frete = formatCurrencyAsText(item.val_frete);
        item.val_icms = formatCurrencyAsText(item.val_icms);
        item.val_icms_st = formatCurrencyAsText(item.val_icms_st);
        item.val_isento = formatCurrencyAsText(item.val_isento);
        item.val_outros = formatCurrencyAsText(item.val_outros);
        item.val_total = formatCurrencyAsText(item.val_total);
        item.qtd_entrada = item.qtd_entrada?.toString().replace(/\./g, ',');
        item.qtd_total = item.qtd_total?.toString().replace(/\./g, ',');
        return item;
      });
      return itensFiscalNF;
    }
    return [];
  } catch (error: any) {
    toast.warning('Itens da NF não encontrado');
    return [];
  }
}

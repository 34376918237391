import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useManutencaoFiscal } from '../../ManutencaoFiscalContext';
import { ContainerScreens } from './styles';
import { DataGrid } from '@material-ui/data-grid';

export const NFe: React.FC = () => {
  const { nfes } = useManutencaoFiscal();

  const associadoColumns = [
    {
      field: 'cod_log',
      headerName: 'Id',
      hide: true,
      width: 200,
      disableColumnMenu: true,
    },
    {
      field: 'dta_cadastro',
      headerName: 'Data Envio',
      width: 300,
      disableColumnMenu: true,
    },
    {
      field: 'cod_status',
      headerName: 'Status',
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: 'mensagem',
      headerName: 'Mensagem',
      width: 400,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'recibo',
      headerName: 'Recibo',
      sortable: false,
      width: 250,
      disableColumnMenu: true,
    },
    {
      field: 'protocolo',
      headerName: 'Protocolo',
      sortable: false,
      width: 250,
      disableColumnMenu: true,
    },
  ];

  return (
    <ContainerScreens>
      <Row>
        <Col md={12} sm={12} style={{ marginTop: '10px', height: '400px' }}>
          <DataGrid
            rows={nfes}
            columns={associadoColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            localeText={{
              noRowsLabel: 'Nenhum registro encontrado',
              columnMenuLabel: 'Menu',
              columnMenuFilter: 'Filtrar',
              columnMenuHideColumn: 'Esconder',
              columnMenuUnsort: 'Não ordenar',
              columnMenuSortAsc: 'Ordernar ASC',
              columnMenuSortDesc: 'Ordernar DESC',
              columnMenuShowColumns: 'Mostrar columnas',
            }}
          />
        </Col>
      </Row>
    </ContainerScreens>
  );
};

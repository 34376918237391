import React from 'react';

import { MarcasProvider } from './contexts/MarcasContext';
import { MarcasContent } from './MarcasContent';

export const Marcas: React.FC = () => {
  return (
    <MarcasProvider>
      <MarcasContent />
    </MarcasProvider>
  );
};

import api from '~/services/api';
import { toast } from 'react-toastify';
import { GtinsProps } from '../../protocols/GtinsProps';
import { SelectProps } from '../../protocols/SelectProps';

export async function getGTINs(cod_produto: string): Promise<SelectProps[]> {
  try {
    const { data } = await api.get(`/manutencao-fiscal/gtins/${cod_produto}`);
    if (data.data) {
      const gtins = data.data.map((value: GtinsProps) => {
        const gtinsValue = {
          label: value.cod_gtin,
          value: Number(value.cod_gtin),
          flg_inativo: value.flg_inativo,
        };
        return gtinsValue;
      });
      return gtins;
    }
    return [];
  } catch (error: any) {
    toast.warning('GTIN não encontrado');
    return [];
  }
}

import api from '~/services/api';
import { toast } from 'react-toastify';
import { CfopProps } from '../../protocols/CfopProps';
import { SelectProps } from '../../protocols/SelectProps';

export async function getCFOPs(
  flg_interestadual: boolean,
  tipo_operacao: number,
  flg_cliente: boolean,
): Promise<SelectProps[]> {
  try {
    const { data } = await api.get(`/manutencao-fiscal/cfops`, {
      params: {
        tipo_operacao,
        flg_cliente,
        flg_interestadual,
      },
    });
    if (data.data) {
      const cfops = data.data.map((value: CfopProps) => {
        return { label: value.cfop, value: Number(value.cfop) };
      });

      return cfops;
    }
    return [];
  } catch (error: any) {
    toast.warning('GTIN não encontrado');
    return [];
  }
}

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .buttonContainer {
    width: 100px;
    margin-right: 80px;
  }

  .buttonContainerGravar {
    width: 100px;
    margin-right: 70px;
  }

  @media screen and (max-width: 1199px) {
    .buttonContainer {
      margin-right: 80px;
    }
  }
  @media screen and (max-width: 992px) {
    .buttonContainer {
      width: 180px;
      margin-right: 0px;
    }

    .buttonContainerGravar {
      width: 180px;
      margin-right: 20px;
    }
  }
  @media screen and (max-width: 991px) {
    .buttonContainer {
      width: 140px;
      min-width: 50px;
      margin-right: 0px;
    }

    .buttonContainerGravar {
      width: 140px;
      margin-right: 0px;
    }
  }
  @media screen and (max-width: 767px) {
    .buttonContainer {
      width: 100%;
      min-width: 50px;
      margin-right: 0px;
      margin-bottom: 20px;
    }

    .buttonContainerGravar {
      width: 100%;
      margin-right: 0px;
    }
  }
`;

export const ButtonForm = styled.button`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 160px;
  background-color: #28a745;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 16px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  animation: all 0.25s ease-in;

  &.livro_fiscal {
    background: #8850bf;
  }

  &.CCe {
    background: #989a9c;
  }
  &.cancelar {
    background: #dc3545;
  }

  &.gravar {
    background: #007bff;

    &.disabled {
      cursor: not-allowed;
      background: #989a9c;
      pointer-events: all !important;
    }
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 992px) {
    width: 100% !important;
  }
`;

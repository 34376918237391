import api from '~/services/api';
import { toast } from 'react-toastify';
import { TributacoesProps } from '../../protocols/TributacoesProps';

export async function getTributacoes(): Promise<TributacoesProps[]> {
  try {
    const { data } = await api.get(`/manutencao-fiscal/tributacoes`);
    if (data.data) {
      return data.data;
    }
    return [];
  } catch (error: any) {
    toast.warning('Tributações não encontradas');
    return [];
  }
}

import * as yup from 'yup';

// const produtoSchema = yup.object({
//   id: yup.string().required(),
//   cod_produto: yup.string().required(),
//   des_produto: yup.string().required(),
// });

export const marcaSchema = yup.object({
  des_marca: yup.string().trim().required().max(50),
  // produtos: yup.array().of(produtoSchema).required(),
});

export type MarcasProps = yup.InferType<typeof marcaSchema>;
